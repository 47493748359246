import React from 'react';
import { Card, Col } from 'antd';
import styled from '@emotion/styled';

const SponsorCardWrapper = styled.div`
  margin-top: 25px;
`;

const StyledCol = styled(Col)`
  padding: 0 15px;
`;

const StyledCard = styled(Card)`
  cursor: pointer;
  margin-bottom: 32px;
`;

const Image = styled.img`
  height: 245px;
  object-fit: contain;
  border-radius: 5px;
`;

const SponsorsContent = ({ data }: any) => {
  return (
    data.length &&
    data.map((sponsor: any) => {
      return (
        <SponsorCardWrapper key={`wrapper-${sponsor.directusId}`}>
          <SponsorCard key={sponsor.directusId} sponsor={sponsor} />
        </SponsorCardWrapper>
      );
    })
  );
};

export default SponsorsContent;

type SponsorCardProps = {
  sponsor: any;
};

export const SponsorCard: React.FC<SponsorCardProps> = ({ sponsor }) => {
  return (
    <StyledCol key={sponsor.directusId} lg={8} md={12} xs={12}>
      <StyledCard bordered cover={<Image alt="sponsor" src={sponsor.img_file.data.full_url} />}></StyledCard>
    </StyledCol>
  );
};
