import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from '@reach/router';
import { graphql, Link } from 'gatsby';
import styled from '@emotion/styled';
import { Row, Col } from 'antd';
import { find } from 'lodash';

import HonoreesContent from './content-components/honorees/honorees';
import GalleryContent from './content-components/gallery';
import VideosContent from './content-components/videos';
import SponsorsContent from './content-components/sponsors';

import theme from '../../theme';
import { useI18NContext } from '../../i18n';
import { navigateTo } from '../../state/actions/routing';
import { InternalContainer, Header, StyledH6 } from '../basic-styles';
import PublicLayout from '../../layout/public-layout';
import PageHeader from '../../components/common/page-header';
import SectionNavComponent from '../../components/common/section-navigation';

const PastEventDetailContainer = styled(InternalContainer)`
  padding-top: 40px;
`;

const SectionHeader = styled(Header)`
  padding: 0;
  margin-bottom: 40px;
`;

const SectionSubHeader = styled(StyledH6)`
  line-height: 29px;
  margin-bottom: 15px;
`;

const StyledCol = styled(Col)`
  margin-top: 20px;

  @media (min-width: ${theme.screenMd}) {
    margin-top: 0;
  }
`;

const SectionHeaderWrapper = styled.div`
  @media (max-width: ${theme.screenXsMax}) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const SectionWrapper = styled.ul`
  overflow: hidden;
  @media (max-width: ${theme.screenXsMax}) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

// The query used to provide the page data
export const pageQuery = graphql`
  query($language: String) {
    honoreesSection: allDirectusPastEventsHonoreesSection(filter: { status: { eq: "published" } }) {
      nodes {
        parent_event {
          slug
          translations {
            language
            name
          }
        }
        section_name
        section_url
        translation(language: $language) {
          section_name
        }
      }
    }
    honoreesContent: allDirectusPastEventsHonoreesSectionContent(filter: { status: { eq: "published" } }) {
      nodes {
        directusId
        parent_event {
          slug
          name
        }
        section_url
        img_file {
          id
          data {
            full_url
          }
        }
        honoree_name
        honoree_position
        honoree_slug
      }
    }
    gallerySection: allDirectusPastEventsGallerySection(filter: { status: { eq: "published" } }) {
      nodes {
        parent_event {
          slug
          name
        }
        section_name
        section_url
        translation(language: $language) {
          section_name
        }
      }
    }
    galleryContent: allDirectusPastEventsGallerySectionContent(filter: { status: { eq: "published" } }) {
      nodes {
        directusId
        parent_event {
          slug
          name
        }
        section_url
        img_file {
          id
          data {
            full_url
          }
        }
      }
    }
    videosSection: allDirectusPastEventsVideosSection(filter: { status: { eq: "published" } }) {
      nodes {
        parent_event {
          slug
          name
        }
        section_name
        section_url
        translation(language: $language) {
          section_name
        }
      }
    }
    videosContent: allDirectusPastEventsVideosSectionContent(filter: { status: { eq: "published" } }) {
      nodes {
        directusId
        parent_event {
          slug
          name
        }
        section_url
        translation(language: $language) {
          video_content
        }
      }
    }
    sponsorsSection: allDirectusPastEventsSponsorsSection {
      nodes {
        parent_event {
          slug
          name
        }
        section_name
        section_url
        translation(language: $language) {
          section_name
        }
      }
    }
    sponsorsContent: allDirectusPastEventsSponsorsSectionContent(filter: { status: { eq: "published" } }) {
      nodes {
        directusId
        parent_event {
          slug
          name
        }
        section_url
        img_file {
          id
          data {
            full_url
          }
        }
      }
    }
  }
`;

const PastEventDetail = ({
  location,
  data: {
    honoreesSection,
    honoreesContent,
    gallerySection,
    galleryContent,
    videosSection,
    videosContent,
    sponsorsSection,
    sponsorsContent,
  },
}: RouteComponentProps<any>) => {
  const dispatch = useDispatch();
  const { language, translate, translateSlug } = useI18NContext();

  const getParentEventUrl = (sectionUrl: string) => {
    const transformed = sectionUrl.split('/');
    const current = transformed[1];
    const result = `/${current}`;

    return result;
  };

  const urlPath = language === 'es' ? location?.pathname.replace('/es', '') : location?.pathname;

  const honoreesSectionItem =
    urlPath && honoreesSection.nodes.filter((item: any) => item.parent_event.slug === getParentEventUrl(urlPath));
  const gallerySectionItem =
    urlPath && gallerySection.nodes.filter((item: any) => item.parent_event.slug === getParentEventUrl(urlPath));
  const videosSectionItem =
    urlPath && videosSection.nodes.filter((item: any) => item.parent_event.slug === getParentEventUrl(urlPath));
  const sponsorsSectionItem =
    urlPath && sponsorsSection.nodes.filter((item: any) => item.parent_event.slug === getParentEventUrl(urlPath));

  const splitItems = [...honoreesSectionItem, ...gallerySectionItem, ...videosSectionItem, ...sponsorsSectionItem];

  const mappedMenuItems: any[] = splitItems.map((item: any) => {
    return {
      value: item.translation?.section_name,
      name: item.section_name,
      slug: item.section_url,
    };
  });

  const currentUrlActiveItem = find(mappedMenuItems, (item: { slug: string }) => {
    return item.slug === urlPath;
  });

  const [activeItem, setActiveItem] = useState<any>(currentUrlActiveItem);

  const handleClick = (sectionName: string, slug: string) => {
    const navItem = find(mappedMenuItems, (item: { slug: string }) => {
      return item.slug === urlPath;
    });

    if (navItem && sectionName) {
      setActiveItem(navItem);
    }
    dispatch(navigateTo(translateSlug(slug)));
  };

  const dynamicHeader = activeItem && activeItem.value !== undefined ? activeItem.value : '';

  const honoreesContentItem =
    urlPath && honoreesContent.nodes.filter((item: any) => item.parent_event.slug === getParentEventUrl(urlPath));

  const galleryContentItem =
    urlPath && galleryContent.nodes.filter((item: any) => item.parent_event.slug === getParentEventUrl(urlPath));

  const videosContentItem =
    urlPath && videosContent.nodes.filter((item: any) => item.parent_event.slug === getParentEventUrl(urlPath));

  const sponsorsContentItem =
    urlPath && sponsorsContent.nodes.filter((item: any) => item.parent_event.slug === getParentEventUrl(urlPath));

  const isActiveValid = activeItem && activeItem.slug !== undefined;

  const isSectionValid = honoreesSectionItem.length && honoreesSectionItem[0].parent_event !== undefined;

  return (
    <PublicLayout seoTitle="Past Event">
      <PastEventDetailContainer>
        <Row justify="space-between">
          <PageHeader
            title=""
            breadcrumb={[
              <Link key="1" to={translateSlug('/')}>
                Home
              </Link>,
              <Link key="2" to={translateSlug('/alumni-hall-of-fame')}>
                Events: Signature Events
              </Link>,
              (isSectionValid && translate(honoreesSectionItem[0].parent_event).name) || '',
            ]}
            fullWidth
            isResponsive
            noShadow
          />
          {splitItems.length && (
            <Col xs={24} md={6}>
              <SectionNavComponent navItems={mappedMenuItems} activeItem={activeItem} handleClick={handleClick} />
            </Col>
          )}
          <StyledCol xs={24} md={18}>
            <SectionHeaderWrapper>
              <SectionHeader>
                {(isSectionValid && translate(honoreesSectionItem[0].parent_event).name) || ''}
              </SectionHeader>
              <SectionSubHeader>{dynamicHeader}</SectionSubHeader>
            </SectionHeaderWrapper>
            <SectionWrapper>
              {isActiveValid && activeItem.slug.includes('honorees') ? (
                <HonoreesContent data={honoreesContentItem} />
              ) : (
                ''
              )}
              {isActiveValid && activeItem.slug.includes('gallery') ? <GalleryContent data={galleryContentItem} /> : ''}
              {isActiveValid && activeItem.slug.includes('videos') ? <VideosContent data={videosContentItem} /> : ''}
              {isActiveValid && activeItem.slug.includes('sponsors') ? (
                <SponsorsContent data={sponsorsContentItem} />
              ) : (
                ''
              )}
            </SectionWrapper>
          </StyledCol>
        </Row>
      </PastEventDetailContainer>
    </PublicLayout>
  );
};

export default PastEventDetail;
