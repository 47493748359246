import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Lightbox } from 'react-modal-image';

import theme from '../../../theme';

const GalleryCardWrapper = styled.div`
  margin-top: 25px;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${theme.screenMd}) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
  }
`;

const Inner = styled.img`
  page-break-inside: avoid;
  break-inside: avoid;
  width: 100%;
  height: 260px;
  border-radius: 4px;
  margin: 15px 0;
  cursor: pointer;
  @media (min-width: ${theme.screenMd}) {
    margin: 0;
  }

  &:nth-of-type(1) {
    height: 260px;
  }
  &:nth-of-type(2) {
    height: 150px;
  }
  &:nth-of-type(3) {
    height: 260px;
  }
  &:nth-of-type(4) {
    height: 150px;
  }
  &:nth-of-type(5) {
    height: 260px;
    @media (min-width: ${theme.screenMd}) {
      margin-top: -110px;
    }
    margin-top: 0;
  }
  &:nth-of-type(6) {
    height: 260px;
  }
  &:nth-of-type(7) {
    height: 260px;
  }
  &:nth-of-type(8) {
    height: 150px;
  }
  &:nth-of-type(9) {
    height: 260px;
  }
`;

const GalleryContent = ({ data }: any) => {
  const [currentImage, setCurrentImage] = useState('');
  const [modal, openModal] = useState(false);

  const openLightbox = (imgUrl: string) => {
    setCurrentImage(imgUrl);
    openModal(true);
  };

  const closeLightbox = () => {
    openModal(false);
  };

  return data ? (
    <GalleryCardWrapper>
      <Box>
        {data.map((item: any) => (
          <Inner
            key={item.directusId}
            alt={item.parent_event.name}
            src={item.img_file.data.full_url}
            onClick={() => openLightbox(item.img_file.data.full_url)}
          />
        ))}
      </Box>
      {modal && <Lightbox medium={currentImage} onClose={closeLightbox} hideDownload hideZoom />}
    </GalleryCardWrapper>
  ) : (
    <GalleryCardWrapper />
  );
};

export default GalleryContent;
