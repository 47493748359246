import React from 'react';
import DOMPurify from 'isomorphic-dompurify';
import styled from '@emotion/styled';

const VideosCardWrapper = styled.div`
  margin-top: 25px;
`;

const VideosContent = ({ data }: any) => {
  return (
    data.length &&
    data.map((video: any) => {
      return (
        <VideosCardWrapper key={`wrapper-${video.directusId}`}>
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(video.translation?.video_content, { ADD_TAGS: ['iframe'], ADD_ATTR: ['target'] }),
            }}
          />
        </VideosCardWrapper>
      );
    })
  );
};

export default VideosContent;
